<template>
  <section :class="backgroundColor +'  pa-10'">
    <v-container>
      <v-row>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="12" md="4">
          <app-title class="mb-8">Courte présentation de l'étude</app-title>
          <p>  
            Expérimentation demande de soins non programmés URPS Pharmaciens Grand Est par Julien Gravoulet 
          </p>
        </v-col>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="12" md="5">
          <v-responsive :aspect-ratio="16 / 9" id="video-container">
            <iframe
              width="100%"
              height="100%"
              src="https://prezi.com/v/embed/xfuz1ywuck4g/"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-responsive>
        </v-col>
        <v-col cols="0" md="1"></v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'VideoPresentationSection',
  components: {
    'app-title': () => import('../../../core/Title'),
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    backgroundColor() {
      return this.currentUser ? 'grey lighten-4' : 'white' 
    }
  }
}
</script>

<style scoped>
#video-container {
  background-color: brown;
  width: 100%;
}
</style>

